<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد فایل های فروشنده جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد فایل های فروشنده جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      store_name: "",
      store_url: "",
      model: {
        user_id: "",
        product_id: "",
        price: "",
        commission: "",
      },
      schema: {
        user_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "کاربر",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        product_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "فایل",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        price: {
          type: "CustomInput",
          label: "قیمت",
          rules: [Constants.rules.required],
          required: true,
        },
        commission: {
          type: "CustomInput",
          label: "کمیسیون",
          rules: [
            Constants.rules.required,
            (v) => (v >= 0 && v < 100) || "این مقدار باید بین 0 تا 100 باشد.",
          ],
          required: true,
        },
      },
    };
  },
  created() {
    this.getUsers();
    this.getProducts();
  },

  methods: {
    getUsers() {
      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: [
              {
                id: 1,
                value: "ادمین",
              },
              ...response.data.map((val) => ({
                id: val.id,
                value: val.first_name + " " + val.last_name,
              })),
            ],
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getProducts() {
      MyAxios.get("/products/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.product_id = {
            ...this.schema.product_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post("vendors/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "فایل با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
